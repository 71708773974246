<template>
  <a-form-model layout="inline">
    <div :class="['flex', 'flex-wrap']">
      <div class="flex flex-wrap flex-1" style="gap: 15px;">
        <template v-for="(item, index) in formItems">
          <a-form-model-item :key="index" :label="item.label">
            <template v-if="item.formType === 'render'">
              <CustomRender
                :render="() => item.customRender(form)"
              ></CustomRender>
            </template>

            <template v-if="item.formType === 'input'">
              <a-input
                :placeholder="item.placeholder"
                v-model="form[item.props]"
              ></a-input>
            </template>

            <template v-if="item.formType === 'select'">
              <async-select
                :async-options="item.options"
                v-model="form[item.props]"
                :placeholder="item.placeholder"
              ></async-select>
            </template>
          </a-form-model-item>
        </template>

        <slot name="extra-left"></slot>
      </div>

      <a-form-model-item style="margin-left: auto; margin-right: 0;">
        <div :class="['flex', 'right-buttons']">
          <slot name="extra-right"></slot>
        </div>
      </a-form-model-item>
    </div>
  </a-form-model>
</template>
<script>
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import AsyncSelect from '@/components/async-select';
import CustomRender from './render';

/** @typedef { import('vue').CreateElement } CreateElement */
/** @typedef { { props: string, defaultValue: string, formType: string, customRender: () => Promise<CreateElement> } } FormItemsType */

@Component({ components: { AsyncSelect, CustomRender } })
export default class TableHeader extends Vue {
  /** @type { FormItemsType } */
  @Prop({ type: Array, default: () => [] }) formItems;

  form = {};
  isInit = true;

  @Watch('form', { deep: true })
  formChange(val) {
    if (this.isInit) {
      this.isInit = false;
    } else {
      this.$emit('search', val);
    }
  }

  initForm() {
    const obj = {};
    if (this.formItems.length) {
      this.formItems.forEach(item => {
        if (item.props) {
          obj[item.props] = item.defaultValue || undefined;
        }
      });
    }
    this.form = obj;
  }

  created() {
    this.initForm();
  }
}
</script>
<style lang="less" scoped>
.right-buttons {
  gap: 10px;
}
</style>
